<template>
  <div>
    <el-dialog :title="title" :visible.sync="visible" width="650px" :close-on-click-modal="false">
      <Table
        :table-data="detailTable"
        :max-height="'450px'"
        :columns="columns"
      />
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="$emit('input',false)">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Table from '@/components/Table'

export default {
  components: { Table },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    detailTable: {
      type: Array,
      default: () => []
    },
    columns: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
    }
  },

  computed: {
    visible: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  watch: {

  },
  created() {

  },
  mounted() {
  },
  methods: {

  }
}
</script>

<style scoped lang="scss">
  .fileType{
    display: flex;
    flex-direction: column;
    align-items: center;
    /deep/ .el-button--default{
      width: 200px;
    }
  }
</style>
