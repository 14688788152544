<template>
  <div>
    <el-dialog title="下载" :visible.sync="visible" width="500px" :close-on-click-modal="false">
      请选择下载文件类型
      <el-row class="fileType">
        <el-row v-for="(item,index) in fileTypeList" :key="index" class="mb-2 mt-2"><el-button @click="handleFileType(item.value,row.id)">{{ item.label }}</el-button></el-row>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    api: {
      type: Function,
      default: () => {}
    },
    fileTypeList: {
      type: Array,
      default: () => []
    },
    row: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {

    }
  },

  computed: {
    visible: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  watch: {

  },
  created() {

  },
  mounted() {
  },
  methods: {
    async handleFileType(fileType, id) {
      const { datas } = await this.api({ fileType, id })
      datas && window.open(datas, '_blank') && this.$emit('input', false)
    }
  }
}
</script>

<style scoped lang="scss">
  .fileType{
    display: flex;
    flex-direction: column;
    align-items: center;
    /deep/ .el-button--default{
      width: 200px;
    }
  }
</style>
