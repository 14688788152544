<template>
  <div>
    <el-dialog title="用印申请" :visible.sync="visible" width="600px" :close-on-click-modal="false">
      请核对供应商盖章文件是否满足用印申请条件：
      <el-form ref="form" :model="form" class="demo-form-inline mt-4" label-width="120px" :rules="rules">
        <el-form-item label="用印类别" prop="signPrintType">
          <Select
            v-model="form.signPrintType"
            :select-options="_getAllCommodityDict('SIGN_PRINT_TYPE')"
            :configuration="optionsConfig"
            clearable
          />
        </el-form-item>

        <el-form-item label="印章所属公司" prop="organizationCode">
          <Select
            v-model="form.organizationCode"
            api-key="organization"
            clearable
            disabled
          />
        </el-form-item>

        <el-form-item label="印章类型" prop="signSealType">
          <Select
            v-model="form.signSealType"
            api-key="sealtype"
            clearable
            ganged
            :params="{organizationCode:form.organizationCode}"
            @responseData="val=>signSealTypeList=val"
          />
        </el-form-item>
        <el-form-item label="签署类型" prop="signSignatureType">
          <Select
            v-model="form.signSignatureType"
            :select-options="_getAllCommodityDict('SIGN_SIGNATURE_TYPE')"
            :configuration="optionsConfig"
            clearable
            multiple
          />
        </el-form-item>
        <el-form-item label="用印时间" prop="signDate">
          <el-date-picker
            v-model="form.signDate"
            type="date"
            value-format="yyyy-MM-dd"
          />
        </el-form-item>
        <el-form-item label="备注">
          <el-input
            v-model="form.remark"
            placeholder=""
            type="textarea"
            :autosize="{ minRows: 2}"
            maxlength="250"
            show-word-limit
          />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="$emit('input',false)">取消</el-button>
        <el-button type="primary" :loading="submitLoading" @click="handleSubmit(true)">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import commodityInfoDict from '@/mixin/commodityInfoDict.js'
import Select from '@/components/Selection'
import dayjs from 'dayjs'

export default {
  components: { Select },
  mixins: [commodityInfoDict],
  props: {
    value: {
      type: Boolean,
      default: false
    },
    row: {
      type: Object,
      default: () => { }
    },
    multipleSelection: {
      type: Array,
      default: () => { return [{ organizationCode: '' }] }
    },
    api: {
      type: Function,
      default: () => {}
    }
  },

  data() {
    return {
      signSealTypeList: [],
      loading: false,
      form: { signDate: dayjs(new Date()).format('YYYY-MM-DD'), organizationCode: '', signSignatureType: [], signPrintType: '', signSealType: '' },
      submitLoading: false,
      optionsConfig: { key: 'val', label: 'label', value: 'val' },
      rules: {
        signPrintType: [{ required: true, message: '必填', trigger: 'change' }],
        signSealType: [{ required: true, message: '必填', trigger: 'change' }],
        signSignatureType: [{ required: true, message: '必填', trigger: 'change' }],
        signDate: [{ required: true, message: '必填', trigger: 'change' }],
        organizationCode: [{ required: true, message: '必填', trigger: 'change' }]
      }

    }
  },

  computed: {
    visible: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    params() {
      const idList = []
      const { signSealType } = this.form
      const sealId = this.signSealTypeList.find(item => item.sealTypeCode === signSealType)?.sealId
      this.multipleSelection.map(item => idList.push(item.id))
      return Object.assign({}, this.form, { idList, sealId })
    }
  },
  watch: {
    'visible'(val) {
      if (!val) {
        this.form = this.$options.data.call(this).form
      } else {
        this.form.organizationCode = this.multipleSelection[0].organizationCode
      }
      this.$refs.form && this.$refs.form.clearValidate()
    }
  },
  created() {

  },
  mounted() {

  },
  methods: {
    handleChange(val) {
      this.multipleSelection = val
    },
    handleSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.handleSure()
        }
      })
    },
    async handleSure() {
      try {
        this.submitLoading = true
        const { msg } = await this.api(this.params)
        this.$notify({
          title: msg,
          message: msg,
          type: 'success'
        })

        this.$emit('input', false)
        this.$emit('refresh')
        this.$parent.closeDialog()
      } finally {
        this.submitLoading = false
      }
    }

  }
}
</script>

<style scoped lang="scss">
</style>
